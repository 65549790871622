export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
};

export const fonts = {
  blackItalic: "Milo ADAC Black Italic",
  bold: "Milo ADAC Bold",
  boldItalic: "Milo ADAC Bold Italic",
  medium: "Milo ADAC Medium",
  regular: "Milo ADAC Regular",
  sizes: {
    h1: 40,
    h1Mobile: 36,
    h2: 24,
    big: 20,
    normal: 16,
    appNormal: 19,
    small: 14,
    smallest: 12,
  },
  weights: {
    bold: 700,
    bolder: 900,
    normal: 400,
  },
};

export const spacing = {
  default: 8,
  fn: (scale = 1) => scale * spacing.default,
};

export const zIndices = {
  header: 200,
  menu: 201,
};
