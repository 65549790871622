import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { up } from "../grid";

export interface ContentfulEmbeddedCookieSettings {
  className?: string;
  title: string;
}

export const CMPGlobalStyles = createGlobalStyle`
  #cmpbox a:after {
    display: none;
  }
`;

const CMPGlobalStyleOverwrite = createGlobalStyle`
  #cmpwrapper,
  body > #cmpbox {
    display: none !important;
  }
`;

export const CookieSettingsBase = ({ title, className = "" }: ContentfulEmbeddedCookieSettings) => {
  useEffect(() => {
    if (!window["cmpmngr"]) {
      return;
    }
    setTimeout(() => {
      window["cmpmngr"].cnfDone = false;
      window["cmp_loadCS"]();
    }, 1000);
  }, []);

  return (
    <div className={className} id="embed-cookie-settings">
      <CMPGlobalStyleOverwrite />
      {title && <h2 className="cookie-settings-title">{title}</h2>}
      <div id="cmpinlinepreferencesbox"></div>
    </div>
  );
};

export const CookieSettings = styled(CookieSettingsBase)`
  #cmpinlinepreferencesbox {
    padding: ${({ theme }) => theme.spacing.fn(2)}px 0;
    border-top: 1px solid ${({ theme }) => theme.palette.common.divider.main};
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.divider.main};
    min-height: 518px;

    #cmpbox {
      display: block !important;
    }

    /* Displayed while loading */
    &:empty {
      background: linear-gradient(135deg, rgba(175, 175, 175, 0) 0%, rgba(175, 175, 175, 0.5) 100%);
    }

    .cmptbl {
      width: calc(100vw - ${({ theme }) => theme.spacing.fn(3.5)}px);

      ${up("sm")} {
        width: 100%;
      }
    }

    .cmpboxtxt {
      width: calc(100vw - ${({ theme }) => theme.spacing.fn(3.5)}px);
      overflow: scroll;

      ${up("sm")} {
        width: auto;
        overflow: hidden;
      }
    }

    .cmpdatacategory {
      white-space: nowrap;
      display: inline-block;
      line-height: 1.1;
      padding: ${({ theme }) => theme.spacing.fn(0.25)}px ${({ theme }) => theme.spacing.fn(0.75)}px;
      margin-top: ${({ theme }) => theme.spacing.fn(0.5)}px;
      margin-bottom: ${({ theme }) => theme.spacing.fn(0.5)}px;
    }

    .cmpvendorbox1 {
      width: auto;
    }

    .cmpvendorbox2:not(:empty),
    .cmppurposebox2:not(:empty) {
      padding-top: ${({ theme }) => theme.spacing.fn(6)}px;
      padding-bottom: ${({ theme }) => theme.spacing.fn(2)}px;
      padding-right: 0;

      ${up("sm")} {
        padding-top: ${({ theme }) => theme.spacing.fn(2)}px;
        padding-right: ${({ theme }) => theme.spacing.fn(4)}px;
      }
    }

    .cmpvendboxclose {
      top: ${({ theme }) => theme.spacing.fn(2)}px;
    }

    a:after {
      display: none;
    }

    .cmpbox {
      padding: 0 0 ${({ theme }) => theme.spacing.fn()}px 0 !important;
    }

    .cmplastupdate {
      bottom: ${({ theme }) => theme.spacing.fn()}px 0;
      left: 0;
    }
  }

  .cookie-settings-title {
    margin: 0 0 ${({ theme }) => theme.spacing.fn(2)}px;
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: ${({ theme }) => theme.fonts.sizes.big}px;
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    line-height: 1.3;
  }
`;
