import { Script } from "gatsby";
import React from "react";
import { PageLoadingState } from "../src/components/page-loading-state/page-loading-state";
import { injectCMPEventListenerScript, injectCMScript } from "../src/utils/consent/consent-manager-script";
import { CMPGlobalStyles } from "../src/components/cookie-settings/cookie-settings";

export const WrapRootElement = () => {
  const GATSBY_CMP_ID = process.env.GATSBY_CMP_ID || "";

  return (
    <>
      <Script>
        {`
          ${injectCMScript(GATSBY_CMP_ID)}
          ${injectCMPEventListenerScript()}
        `}
      </Script>
      <CMPGlobalStyles />
      <PageLoadingState />
    </>
  );
};
